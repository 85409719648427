/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');
@import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/*
@import 'material-icons/iconfont/material-icons.css';
*/
html, body {
  height: 100%;

}

mat-icon {
  height: auto !important;
}

:root {
  --myvar: '#ABCDEF';
}

* {
  margin: 0px;
  padding: 0px;
}

form-field-docType {
  width: 22%;
  height: fit-content;
}

body {
  font-family: 'Exo', sans-serif;
}

.icon {
  fill: #29648A;
}

.icon:hover {
  filter: invert(0.3) sepia(1);
}

.icon:active {
  filter: invert(0.6) sepia(1);
}

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.flexform {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.form-field .mat-form-field-wrapper {
  padding-bottom: 16px;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: black;
}

.cardcomponentMasks2 {
  overflow: auto;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.96);
  width: 32%;
  margin: auto;
  min-height: 70%;
  height: 96%;
  -webkit-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  color: #29648A;
}

.cardcomponentMasks1 {
  width: 49%;
  margin: auto;
  min-height: 50%;
  height: 96%;
  color: #29648A;
}

.mat-dialog-container {
  /*height: inherit !important;*/
  height: unset !important;
}

/* input color class */
.mat-input-element {
  color: black !important;
}


.cardcomponentMasks {
  overflow: auto;
  overflow-y: scroll;
  background: rgba(255, 255, 255, 0.96);
  width: 100%;
  margin: auto;
  min-height: 70%;
  height: 100%;
  -webkit-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  color: #29648A;
}

.cardcomponentMasksTable {

  background: rgba(255, 255, 255, 0.96);
  width: 95%;
  margin: auto;
  height: 80%;

  -webkit-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.75);
  color: #29648A;
  margin-bottom: 10%;
}

.cdk {
  display: flex;
  padding-bottom: 34.1%;
}

.cdkD {
  display: flex;
  padding-bottom: 45%;
}

@media only screen and (max-height: 700px) {
  .cdk {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 30% !important;
    border: 0px solid black;
  }
}

@media only screen and (max-width: 700px) {
  .cdk {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 110% !important;
    border: 0px solid black;
  }
}

@media only screen and (max-width: 750px) {
  .cdk {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 90%;
    border: 0px solid black;
  }
}

@media only screen and (max-width: 1200px) {
  .cdk {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 45%;
    border: 0px solid black;
  }
}


.context h1 {
  text-align: center;
  color: #fff;
  font-size: 40px;
}


.area {
  background: #EFEFEF;

  width: 100%;
  height: 100vh;


}

.cardcomponent {
  background: rgba(255, 255, 255, 0.9);
  height: fit-content;
  width: 95%;
  margin: auto;
  margin-bottom: 4%;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
  color: #29648A;
}

.center {
  margin-top: 1%;
  margin-right: 1%;
  margin-left: 1%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 89%;
}

#style-4::-webkit-scrollbar-track {
  background-color: white;
}

#style-4::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  background-color: grey;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: grey;
  border: 2px solid white;

}

*::-webkit-scrollbar-track {
  background-color: white;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: grey;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border: 0px solid white;
}

#style-5::-webkit-scrollbar-track {
  background-color: white;
}
#style-4::-webkit-scrollbar-track {
  background-color: white;
}
#style-5::-webkit-scrollbar {
  height: 15px;
  width: 15px;
  background-color: grey;
}

#style-5::-webkit-scrollbar-thumb {
  background-color: grey;
  border: 2px solid white;
}

.viewport {

  margin-right: 2%;
  margin: 2%;
  padding-bottom: 25%;
  border: 0px solid black;
}

.item {
  height: 50px;
}

.mat-list-item:hover {
  background: rgb(226, 226, 226);
  cursor: pointer;
}

.mat-list-item:active {

}

.riga {
  border-top: 2px solid;
}

.mat-selection-list {
  outline: none;
}

.searchdimension {
  width: 80%;
}

@media only screen and (max-width: 800px) {
  .viewport {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 45%;
    border: 0px solid black;
  }
}

.icon {
  fill: #29648A;
}

.icon:hover {

  filter: invert(0.3) sepia(1);
}

.icon:active {
  filter: invert(0.6) sepia(1);
}

@media only screen and (max-height: 850px) {
  .viewport {
    margin-right: 2%;
    margin: 2%;
    padding-bottom: 17%;
    border: 0px solid black;
  }


}

.cardcomponent {
  background: rgba(255, 255, 255, 0.96) !important;
}

.style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

.style-4::-webkit-scrollbar-thumb {
  background-color: #29648A;
  border: 2px solid #555555;
}

.custom-danger-bg {
  background: #ff4444;
  color: #fff;
}

.custom-warning-bg {
  background: #ffbb33;
  color: #fff;
}

.custom-success-bg {
  background: #00C851;
  color: #fff;
}

::ng-deep ngx-material-timepicker-content {
  --body-background-color: #fff;
  --primary-font-family: 'Roboto', sans-serif;
  --button-color: black !important;
  --dial-active-color: #fff;
  --dial-inactive-color: rgba(255, 255, 255, .5);
  --dial-background-color: black !important;
  --dial-editable-active-color: black !important;
  --dial-editable-background-color: #fff;
  --clock-face-time-active-color: #fff;
  --clock-face-time-inactive-color: #6c6c6c;
  --clock-face-inner-time-inactive-color: #929292;
  --clock-face-time-disabled-color: #c5c5c5;
  --clock-face-background-color: #f0f0f0;
  --clock-hand-color: black !important;
}

.custom-info-bg, .mat-badge-content {
  background: #33b5e5;
  color: #fff;
}

.mat-expansion-indicator::after {
  padding: 5px 5px 5px 5px !important;
}

.cdk-overlay-pane {
  max-width: 98% !important;
}

.invisible {
  display: none !important;
}

.presenzaFull{
  background-color: #de7565 !important;
  border-color: #d1e8ff !important;
  color: #fff !important;
}
.presenzaMattino{
  background-color: #f4ccac !important;
  border-color: #d1e8ff !important;
  color: #fff !important;
}

.presenzaPomeriggio{
  background-color: #c2cf9d !important;
  border-color: #d1e8ff !important;
  color: #fff !important;
}
.smartWorking{
  background-color: #b8c1df !important;
  border-color: #d1e8ff !important;
  color: #fff !important;
}
.offWorking{
  background-color: #6a6a6a !important;
  border-color: #d1e8ff !important;
  color: #fff !important;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  box-flex: 1;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  background: rgb(0 0 0 / 15%);
  border-radius: 9px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(0 0 0 / 25%);
}

table.mat-table, .mat-cell, .mat-footer-cell {
  font-size: 12px !important;
}

.mat-header-cell {
  font-size: 13px;
  font-weight: 800;
  font-style: italic;
}

.mat-form-field, .mat-option {
  font-size: 13px;
}

.mat-card-title {
  font-size: 16px;
  font-weight: 500;
}


.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
  font: 500 15px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.hover {
  position: relative !important;
  background: white !important;
  width: 95% !important;
  margin-bottom: 2% !important;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% !important;
  box-shadow: 1px 1px rgb(0 0 0 / 15%) !important;
  transition: all .4s ease !important;
}
.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 13px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-card-subtitle, .mat-card-content {
  font-size: 14px;
}
.mat-card-title {
  font-size: 17px;
  font-weight: 500;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}
em{
  font-weight: 500;
}
.mat-stroked-button{
  margin: 1% !important;
}
.mat-card-actions, .mat-card-subtitle, .mat-card-content {
  display: block;
  margin-bottom: 5px;
}
.mat-drawer-inner-container button{
  text-transform: capitalize !important;
}
.mat-button-toggle-label-content {
  line-height: 20px !important;
  padding: 0 16px;
  font-size: 12px !important;
  text-transform: uppercase;
}
.mat-button-toggle-standalone:not([class*=mat-elevation-z]), .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%),0px 1px 5px 0px rgb(0 0 0 / 5%);
}
